.btn-azur {
  border: 1px solid $color-grey;
  padding: 10px;
  background-color: white;
  border-radius: 2px;
  transition: all 500ms;
  span {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
      width: 30px;
    }
  }
  &:hover {
    // background-color: rgb(238, 238, 238);
    border-color: rgb(71, 71, 71);
    box-shadow: 0px 3px 2px -2px rgb(126, 126, 126);
  }
}

/* Suppression des flèches dans les cellules de calcul */

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

