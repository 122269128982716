.container-ProjectDetails{
    min-width: 100%;
    display: flex;
}

.projectDetails{
    width: 25%;
    margin: 0 15px 0 15px;
}

.projectDetailsSummary{
    width: 75%;
    margin: 0 15px 10px 15px;
}

.projectCostsSummary{
    padding:  0 2% 2% 2%;     
    &__title{
        color: var(--colorDarkBrown);
        height: 100%;
        text-align: center;
        font-weight: bold;
        font-size: 1.2rem;
    }
    &__headings{
        background-color: rgba(128, 128, 128, 0.212);
    }
}
.projectdetails__card{
    margin : 50px 10px 0 10px;
    padding : 5% 8%;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
}
.projectdetails__card p{
    font-size: large;
}

@media screen and (max-width: 1500px) {
    .projectdetails__card p{
        font-size: medium;
    }
    .projectDetailsSummary{
        width: 75%;
        margin: 0 15px 10px 15px;
    }
    
    .projectDetails{
        width: 25%;
        margin: 0 15px 0 15px;
    }
    
}
@media screen and (max-width: 1200px) {
    .container-ProjectDetails{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .projectDetailsSummary{
        width: 100%;
        margin-bottom: 10px;
    }
    .projectDetails{
        width: 100%;
        margin: 0 15px 0 15px;
        &__card{
            margin : 10px;
            padding : 2%;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 5px;
        }
    }
}