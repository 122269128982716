@import "./GlobalVariables/variables";
@import "./Buttons/buttons";
@import "layout";

@import "Project/projectDetailsSummary.scss";
@import"Project/projectPage.scss";
@import "NavTab/navTab.scss";
@import "Tasks/tasksTable.scss";
@import "Matrix/matrix.scss";
// Pour que les liens ne soient pas en couleur bleu

:root{
  --colorDarkOrange: #c78000;
  --colorDarkBrown: #434241;
}

a {
  color: inherit;
  text-decoration: none;
}

img{
  width: 100%
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiDrawer-paperAnchorDockedLeft.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  background-color: #434241;
}

div.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation16.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.css-4t3x6l-MuiPaper-root-MuiDrawer-paper{
  background-color: #434241;
}

.logo{
  width: 10%;
}

.center-cell{
  justify-content: center !important;
}
