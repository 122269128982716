//Navbar
.flex-container-navbar{
    display: flex;
    justify-content: space-between;
    width: 100%;
    .flex-container-left{
        display: flex;
        .logo-kairos{
            transition: all ease-in-out 0.5s;
            &:hover{
                transform : scale(1.2);
            }
        }

    }

}

