/*-----------NavBar Haut-------------*/
.navDetails{
    min-width: calc(100vw - 75px);
}

.flex-container-navbar{
    max-width: 100vw;
}

/*---------------Fleche retour detail projet-----------*/
.backToProjectArrow:hover{
    cursor: pointer;
    color: white;
    transition: ease-in-out 0.2s;
}