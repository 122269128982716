.kairos-checkbox-cell {
    min-width: 50px;
    max-width: 50px;
    width: 50px;
    padding: 4px;
    text-align: center;
}

.kairos-column-header {
    min-width: 200px;
    max-width: 200px;
    width: 200px;
    cursor: pointer;
    padding: 8px;
    user-select: none;
}

.kairos-row {
    cursor: pointer;
    min-height: 60px;
    height: 60px;
}

.kairos-container{
    position: relative;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(224, 224, 224, 1);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.87);
}

.kairos-table-container{
    max-height: 693px;
    overflow: auto;
    position: relative;}

.kairos-table{
    min-height: 200px;
}

.kairos-table-head {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 2;
}

.kairos-table-cell {
    padding: 8px;
    font-size: 0.875rem;
}

.kairos-table-cell:hover{
    background-color: rgba(240, 245, 250, 0.421);
}

.kairos-no-results{
    position: absolute;
    left: 50%;
    bottom: 50%;
    transform: translate(-50%,50%);
}


.arrow-up:hover, .arrow-down:hover{
    background-color: rgba(128, 128, 128, 0.048);
    padding: 10px;
    border-radius: 15px;

}

.arrow-down,.arrow-up{
    margin-left: 15px;
    padding: 10px;

}
