.tasksTableContainer{
    width: 100%;
}

.tasksModal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    background-color: white;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
    padding: 32px;
    border-radius: 4px;
}